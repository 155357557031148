/* app/javascript/stylesheets/components/_form.scss */

@import "dropzone.min.css";
@import "basic.min.css";

.input {
  @apply appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight;
}

.input:focus {
  @apply outline-none bg-white border-gray-400;
}

.label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

.select {
  @apply appearance-none py-3 px-4 pr-8 block w-full bg-gray-100 border border-gray-300 text-gray-700 rounded leading-tight;
  -webkit-appearance: none;
}

.select:focus {
  @apply outline-none bg-white border-gray-400;
}

.dropzone {
  @apply border-2 rounded-lg border-gray-400 border-dashed;

  &.dz-drag-hover {
    @apply border-2 rounded-lg border-gray-200 border-dashed;

    .dz-message {
      opacity: 0.9;
    }
  }
}

.dropzone .dz-remove {
  @apply bg-red-500 text-white rounded-full text-xs px-2 py-1 my-2 w-fit mx-auto;
}

/* Style apply for single upload */
.dropzone[data-dropzone-max-files="1"] .dz-preview {
  @apply w-[90%] left-[5%] h-[200px] my-4;
}

.dropzone[data-dropzone-max-files="1"] .dz-preview .dz-image {
  @apply w-full h-full;
}

.dropzone[data-dropzone-max-files="1"] .dz-preview .dz-image img {
  @apply object-cover w-full h-full;
}