@import "../admin.css";

.text-primary {
  @apply text-[#084278];
}
.text-primary span.special {
  @apply text-[#8F278A];
}
.text-secondary {
  @apply text-[#8F278A];
}

.btn-primary {
  @apply bg-[#A9E5FF];
  @apply text-[#084278];
  @apply rounded-2xl;
  @apply py-2;
  @apply px-4;
  @apply font-bold;
}

.btn-secondary {
  @apply bg-[#600F5C];
  @apply text-white;
  @apply rounded-xl;
  @apply rounded-2xl;
  @apply py-2;
  @apply px-4;
  @apply font-bold;
}

blockquote,dl,figure,form,ol,p,pre,ul {
  margin-bottom: 1.3em
}

form p {
  margin-bottom: .5em
}

body {
  line-height: 1.6
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: .5em;
  margin-top: 0;
  text-rendering: optimizeSpeed;
  width: 100%
}

.ck-container ol,ul {
  padding: 0 40px
}
.ck-container ul li {
  list-style-type: disc;
}
.ck-container ol {
  list-style-type: decimal;
}
.ck-container p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.ck-container a {
  color: #0782C1;
}

.hotline-container {
  display: flex;
  flex-direction: row;
  height: 3rem;
  background-color: #0739AD;
  border-radius: 24px;

}
.hotline-icon {
  display: flex;
  align-items: center;
  height: 100%;

  /* Adjust styles as needed */
}
.hotline-slideout {
  display: flex;
  align-items: center;
  width: 0; /* Initially set width to 0 */
  justify-content: center;
  color: #fff;
  font-weight: 600;
  border: 0px solid #ccc;
  border-radius: 2rem;
  margin-bottom: 2px;
  overflow: hidden; /* Hide overflow content */
  transition: width .0.5s ease-in; /* Transition width property */
}
.hotline-container:hover .hotline-slideout {
  width: 10rem; /* Expand width to reveal slideout */
  height: 3rem
}
.messenger-icon-img {
  display: flex;
  justify-content: end;
}
