@import "./application.tailwind.css";
@import "./override/editorjs.css";
@import "./override/select2.css";
@import "./override/dropzone.css";

.cms-seo-container {
  display: flex;
  width: 100%;
  margin-bottom: 2.4rem;
  padding: 20px 30px 16px;
  border: 1px solid #e6e9eb;
  font-family: Arial,sans-serif;
  background: #fff;
  border-radius: 3px;
}
.cms-seo-container svg {
  width: 92px;
  height: 30px;
  margin-right: 32px;
}
.cms-seo-preview {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: 28px;
}

.cms-seo-search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 28px;
  background: #f4f5f6;
  border-radius: 14px;
}
.cms-seo-search-bar svg {
  width: 20px;
  height: auto;
  margin-right: 12px;
  fill: #80868b;
}
.cms-seo-preview-link, .cms-seo-preview-title {
  overflow: hidden;
  font-family: Arial,sans-serif;
  line-height: 1.3;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}
.cms-seo-preview-link {
  max-width: 340px;
  margin: 2px 0;
  color: #202124;
  font-size: 14px;
}
.cms-seo-preview-title {
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 4px;
  color: #1a0dab;
  font-size: 20px;
  -webkit-text-overflow: ellipsis;
}

.cms-seo-preview-desc {
  color: #4d5156;
  font-family: Arial,sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.gh-twitter-container {
  width: 100%;
  margin-bottom: 2.4rem;
  border: 1px solid #e6e9eb;
  background: #fff;
  border-radius: 3px;
}

.gh-social-twitter-container .social-icon, .gh-twitter-container .social-icon {
  width: 34px;
  height: 34px;
  margin-right: 12px;
}
.gh-social-og-title {
  color: #385898;
  font-size: 14px;
  line-height: 1.38;
  font-weight: 600;
}
.gh-social-og-time {
  color: #616770;
  font-size: 12px;
  line-height: 1.34;
  font-weight: 400;
}
.gh-social-og-desc {
  display: inline-block;
  height: 10px;
  background: #e9ebee;
  border-radius: 5px;
}
.gh-social-twitter-post-preview {
  overflow: hidden;
  color: #292f33;
  font-size: 1.4rem;
  line-height: 1.3em;
  border: 1px solid #c4cfd6;
  background: #fff;
  border-radius: 16px;
  -webkit-font-smoothing: antialiased;
}
.gh-social-twitter-reactions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 0;
  max-width: 425px;
  margin-top: 10px;
  color: #5b7083;
  font-size: 13px;
  font-weight: 400;
}
.gh-social-twitter-preview-content {
  padding: 10px;
}
.gh-social-twitter-preview-title {
  width: 100%;
  max-height: 20px;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: 0 0 2px;
  color: #0f1419;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gh-social-twitter-preview-meta {
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 487px;
  margin-top: 2px;
  color: #5b7083;
  font-size: 15px;
  line-height: 1.3125;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gh-social-twitter-preview-meta svg {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.gh-social-twitter-preview-desc {
  width: 100%;
  max-width: 487px;
  max-height: 40px;
  overflow: hidden;
  overflow-wrap: break-word;
  margin-top: 5px;
  color: #5b7083;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.gh-social-twitter-reactions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 0;
  max-width: 425px;
  margin-top: 10px;
  color: #5b7083;
  font-size: 13px;
  font-weight: 400;
}
.gh-social-twitter-reactions svg {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  fill: #5b7083;
}
.gh-og-container {
  width: 100%;
  margin-bottom: 2.4rem;
  border: 1px solid #e6e9eb;
  background: #fff;
  border-radius: 3px;
}

.gh-social-og-preview {
  background: #f2f3f5;
}
.gh-social-og-preview-bookmark {
  padding: 10px 12px 8px;
  border-bottom: 1px solid #dddfe2;
}
.gh-social-og-preview-content {
  height: 69px;
  overflow: hidden;
  margin: 0 -1px 0 -4px;
  padding: 0 1px 0 4px;
}
.gh-social-og-preview-meta {
  overflow: hidden;
  color: #606770;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gh-social-og-preview-title {
  max-height: 110px;
  overflow: hidden;
  margin: 3px 0 0;
  padding-top: 2px;
  color: #1d2129;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  word-wrap: break-word;
}
.gh-social-og-preview-desc {
  max-height: 20px;
  overflow: hidden;
  margin-top: 3px;
  color: #4b4f56;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}
.social-icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.gh-social-og-reactions {
  display: flex;
  align-items: center;
  margin: 12px;
}
.gh-social-og-likes {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  color: #606770;
  font-size: 13px;
  line-height: 20px;
}
.gh-social-og-likes svg {
  width: 20px;
  height: 20px;
}

.gh-social-og-comments {
  display: flex;
  flex-shrink: 0;
  color: #606770;
  font-size: 13px;
  line-height: 20px;
  word-wrap: break-word;
}

.gjs-devices-c {
  max-height: 28px;
}

#particles-js{
  width: 100%;
  height: 100%;
  background-image: url('');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
}
.thuoc {
  animation: bongbenh 3s infinite;
  
}

.thuoc1 {
  animation: bongbenh 4s infinite;
  
}

.thuoc2 {
  animation: bongbenh 3.5s infinite;
  
}

@keyframes bongbenh {
	0% {
		transform: translatey(0);
	}
	50% {
		transform: translatey(-30px);
	}
	100% {
		transform: translatey(0);
    
	}
}
